import React from 'react';
import { Link, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
// import Call from '../components/Call';  

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'all'
    };
  }
  render() {
    // return (
    //   <Layout bodyclassName='page-blog'>
    //     <SEO title="Blog" />
    //     <div className='container pb-6 pt-6 pt-md-10 pb-md-10'>
    //       <h1 className='title'>Blog</h1>
    //       <h3>Coming soon ...</h3>
    //     </div>
    //   </Layout>
    // );
    const tags = this.props.data.tags.group || [];
    const { posts } = this.props.data.blog || [];
    return (
      <Layout bodyclassName='page-blog'>
        <SEO title='Blog' />
        <div className='container pb-6 pt-6 pt-md-10 pb-md-10'>
          <h1 className='title'>Blog</h1>
          <br />
          <div className='row justify-content-start'>
            <div className='col-16 col-md-7 col-lg-6 col-sm-12 blog-container'>
              <ul className='nav nav-tabs'>
                <li isac className='nav-item'>
                  <a
                    onClick={() => this.setState({ currentTab: 'all' })}
                    className={
                      this.state.currentTab == 'all'
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    href='#'
                  >
                    All
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    onClick={() => this.setState({ currentTab: 'blog' })}
                    className={
                      this.state.currentTab == 'blog'
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    href='#'
                  >
                    Blog
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    onClick={() => this.setState({ currentTab: 'activity' })}
                    className={
                      this.state.currentTab == 'activity'
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    href='#'
                  >
                    Activity
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    onClick={() => this.setState({ currentTab: 'notice' })}
                    className={
                      this.state.currentTab == 'notice'
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                    href='#'
                  >
                    Notice
                  </a>
                </li>
              </ul>
              <br />
              <br />
              {this.state.currentTab == 'all' &&
                posts.map((post) => (
                  <article key={post.id}>
                    <Link to={`/blog${post.fields.slug}`}>
                      <h2>{post.frontmatter.title}</h2>
                    </Link>
                    <small>
                      {post.frontmatter.author}, {post.frontmatter.date}
                    </small>
                    <p>{post.excerpt}</p>
                  </article>
                ))}
              {this.state.currentTab == 'blog' &&
                posts
                  .filter((post) =>
                    post.frontmatter.tags.includes('blog') ? post : null
                  )
                  .map((post) => (
                    <article key={post.id}>
                      <Link to={`/blog${post.fields.slug}`}>
                        <h2>{post.frontmatter.title}</h2>
                      </Link>
                      <small>
                        {post.frontmatter.author}, {post.frontmatter.date}
                      </small>
                      <p>{post.excerpt}</p>
                    </article>
                  ))}
              {this.state.currentTab == 'activity' &&
                posts
                  .filter((post) =>
                    post.frontmatter.tags.includes('activity') ? post : null
                  )
                  .map((post) => (
                    <article key={post.id}>
                      <Link to={`/blog${post.fields.slug}`}>
                        <h2>{post.frontmatter.title}</h2>
                      </Link>
                      <small>
                        {post.frontmatter.author}, {post.frontmatter.date}
                      </small>
                      <p>{post.excerpt}</p>
                    </article>
                  ))}
              {this.state.currentTab == 'notice' &&
                posts
                  .filter((post) =>
                    post.frontmatter.tags.includes('notice') ? post : null
                  )
                  .map((post) => (
                    <article key={post.id}>
                      <Link to={`/blog${post.fields.slug}`}>
                        <h2>{post.frontmatter.title}</h2>
                      </Link>
                      <small>
                        {post.frontmatter.author}, {post.frontmatter.date}
                      </small>
                      <p>{post.excerpt}</p>
                    </article>
                  ))}
            </div>
            <div className='col-8 col-md-5 col-lg-6 col-sm-12'>
              <h2>Tags</h2>
              <hr className='blog-tag-hr' />
              {tags.map((tag) => (
                <span
                  key={`tag-${tag.tag}`}
                  className='badge badge-pill badge-secondary post-tag'
                >
                  <Link className='post-tag-link' to={`/blog/tags/${tag.tag}`}>
                    {tag.tag} ({tag.totalCount})
                  </Link>
                </span>
              ))}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query BlogQuery {
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
    ) {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          author
          tags
        }
        excerpt
        id
        fields {
          slug
        }
      }
    }
    tags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(posts)/" } }
    ) {
      group(field: frontmatter___tags) {
        tag: fieldValue
        totalCount
      }
    }
  }
`;

export default Blog;
